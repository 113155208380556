@function unicode($str) {
	@return unquote("\"") + $str + unquote("\"");
  }

//Iconfont
@mixin iconfont($content: " ", $font-size: inherit, $color: inherit ) {
	font-family: $font-iconfont;
	speak: none;
	font-style: normal;
	font-weight: 100;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	content: unicode($content);
	font-size: $font-size;
	color: $color;
	
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "iconfont";
    src: url('../../assets/media/fonts/iconfont/iconfont.eot?8n9mdf');
    src: url('../../assets/media/fonts/iconfont/iconfont.eot?8n9mdf#iefix') format('eot'),
      url('../../assets/media/fonts/iconfont/iconfont.woff2?8n9mdf') format('woff2'),
      url('../../assets/media/fonts/iconfont/iconfont.woff?8n9mdf') format('woff'),
      url('../../assets/media/fonts/iconfont/iconfont.ttf?8n9mdf') format('truetype'),
      url('../../assets/media/fonts/iconfont/iconfont.svg?8n9mdf#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

$font-iconfont:	"iconfont";

$icon-arrow-left: "\EA01";
$icon-arrow-right: "\EA02";
$icon-browser: "\EA03";
$icon-caret-down: "\EA04";
$icon-caret-left: "\EA05";
$icon-caret-right: "\EA06";
$icon-caret-up: "\EA07";
$icon-check: "\EA08";
$icon-chevron-down: "\EA09";
$icon-chevron-left: "\EA0A";
$icon-chevron-right: "\EA0B";
$icon-chevron-up: "\EA0C";
$icon-circle-empty: "\EA0D";
$icon-circle-full: "\EA0E";
$icon-close: "\EA0F";
$icon-email: "\EA10";
$icon-facebook: "\EA11";
$icon-home: "\EA12";
$icon-instagram: "\EA13";
$icon-linkedin: "\EA14";
$icon-logo-a: "\EA15";
$icon-logo-b: "\EA16";
$icon-menu: "\EA17";
$icon-minus: "\EA18";
$icon-play: "\EA19";
$icon-plus: "\EA1A";
$icon-reset: "\EA1B";
$icon-twitter: "\EA1C";
$icon-vimeo: "\EA1D";
$icon-youtube: "\EA1E";



.icon-arrow-left::before {
  @include iconfont($icon-arrow-left, 15px);
}
.icon-arrow-right::before {
  @include iconfont($icon-arrow-right, 15px);
}
.icon-browser::before {
  @include iconfont($icon-browser, 15px);
}
.icon-caret-down::before {
  @include iconfont($icon-caret-down, 15px);
}
.icon-caret-left::before {
  @include iconfont($icon-caret-left, 15px);
}
.icon-caret-right::before {
  @include iconfont($icon-caret-right, 15px);
}
.icon-caret-up::before {
  @include iconfont($icon-caret-up, 15px);
}
.icon-check::before {
  @include iconfont($icon-check, 15px);
}
.icon-chevron-down::before {
  @include iconfont($icon-chevron-down, 15px);
}
.icon-chevron-left::before {
  @include iconfont($icon-chevron-left, 15px);
}
.icon-chevron-right::before {
  @include iconfont($icon-chevron-right, 15px);
}
.icon-chevron-up::before {
  @include iconfont($icon-chevron-up, 15px);
}
.icon-circle-empty::before {
  @include iconfont($icon-circle-empty, 15px);
}
.icon-circle-full::before {
  @include iconfont($icon-circle-full, 15px);
}
.icon-close::before {
  @include iconfont($icon-close, 15px);
}
.icon-email::before {
  @include iconfont($icon-email, 15px);
}
.icon-facebook::before {
  @include iconfont($icon-facebook, 15px);
}
.icon-home::before {
  @include iconfont($icon-home, 15px);
}
.icon-instagram::before {
  @include iconfont($icon-instagram, 15px);
}
.icon-linkedin::before {
  @include iconfont($icon-linkedin, 15px);
}
.icon-logo-a::before {
  @include iconfont($icon-logo-a, 15px);
}
.icon-logo-b::before {
  @include iconfont($icon-logo-b, 15px);
}
.icon-menu::before {
  @include iconfont($icon-menu, 15px);
}
.icon-minus::before {
  @include iconfont($icon-minus, 15px);
}
.icon-play::before {
  @include iconfont($icon-play, 15px);
}
.icon-plus::before {
  @include iconfont($icon-plus, 15px);
}
.icon-reset::before {
  @include iconfont($icon-reset, 15px);
}
.icon-twitter::before {
  @include iconfont($icon-twitter, 15px);
}
.icon-vimeo::before {
  @include iconfont($icon-vimeo, 15px);
}
.icon-youtube::before {
  @include iconfont($icon-youtube, 15px);
}