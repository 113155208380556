/*-----------*/
/* Variables
/*-----------*/

//Global Grid variables
$global-config:	(
	block--width:	100%,
	width:			1600px,
	columns:		12,
	gutters:		15px,
);

$grid-breakpoints: (
	xs:  0,
	sm:  544px,
	md:  768px,
	lg:  992px,
	xl:  1200px,
	xxl:  1440px,
	xxxl: 1600px,
);

//Colors
$colors: (
	black: #1e1f21,
	white:#ffffff,
	gray: #bdbdbd,
	blue: #132973,
	blue-dark: #133853,
	charcoal: #272b2e,
	pink: #ab1d2b,
	ultralight: #f2f5f7,
	transparent: transparent
);


//Z-Index Management -- utilizes Sass Maps
//Easiest way to use is via the mixin: @include z-index(zero);
$z-index: (
	heaven					:	9000,
	social-share			:	5000,
	hamburger				:	1001,
	nav--mobile				:	1000,
	aboveground				:	100,
	ground					:	1,
	zero					:	0,
	underground				:	-1,
	hell					:	-9000
);