/*--------------*/
/* Typography
/*--------------*/

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,400i,700,800');

@font-face {
    font-family: 'iconfont';
    src:    url('/wp-content/themes/capstj/assets/media/fonts/iconfont/iconfont.eot');
	src:    url('/wp-content/themes/capstj/assets/media/fonts/iconfont/iconfont.eot#iefix') format('embedded-opentype'),
	        url('/wp-content/themes/capstj/assets/media/fonts/iconfont/iconfont.svg') format('truetype'),
	        url('/wp-content/themes/capstj/assets/media/fonts/iconfont/iconfont.woff') format('woff'),
	        url('/wp-content/themes/capstj/assets/media/fonts/iconfont/iconfont.svg#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
}


$font-family-sans-serif:	'Nunito Sans', sans-serif;
$font-iconfont:				'iconfont';

@mixin font-body($color: color(black)) {
	font-family: $font-family-sans-serif;
	@include fluid-type(map-get($grid-breakpoints, 'sm'), map-get($grid-breakpoints, 'xxl'), 14px, 15px);
	font-weight: 400;
	line-height: 1.9;
	@if ($color != inherit) {
	color: $color;
	}

	a {
		@include color(pink);
		text-decoration: none;

		@include hover() {
			color: darken(color(pink), 20);
			text-decoration: underline;
		}
	}
}


@mixin font-navigation($color: color(white)) {
	font-family: $font-family-sans-serif;
	color: $color;
	text-transform: uppercase;
	@include font-size(13px);
	letter-spacing: 0.8px;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 2px solid color(transparent);
	transition: all ease 0.3s;

	@include hover() {
		text-decoration: none;
		border-bottom: 2px solid;
		border-bottom-color: $color;
		transition: all ease 0.3s;
	}
}

@mixin font-small-allcaps($color: color(white)) {
	font-family: $font-family-sans-serif;
	@if ($color != inherit) {
	color: $color;
	}
	@include fluid-type(map-get($grid-breakpoints, 'sm'), map-get($grid-breakpoints, 'xxl'), 10px, 12px);
	letter-spacing: 0px;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
	
	a {
		@if ($color != inherit) {
		color: $color;
		}transition: opacity ease 0.3s;
		text-decoration: none;
	
		@include hover() {
			transition: opacity ease 0.3s;
		}
	}
}

@mixin font-tiny($color: color(white)) {
	font-family: $font-family-sans-serif;
	@if ($color != inherit) {
	color: $color;
	}
	@include font-size(11px);
	letter-spacing: 0.5px;
	font-weight: 300;
	text-decoration: none;
	line-height: 1.5;
	
	a {
		@if ($color != inherit) {
		color: $color;
		}transition: opacity ease 0.3s;
		text-decoration: none;
	
		@include hover() {
			transition: opacity ease 0.3s;
		}
	}
}

@mixin font-btn($color: color(white)) {
	@include font-small-allcaps();
	@if ($color != inherit) {
	color: $color;
	}
	font-weight: 600;
}

@mixin font-headline($color: color(white), $sm-font: 35px, $xxl-font: 42px) {
	font-family: $font-family-sans-serif;
	@include fluid-type(map-get($grid-breakpoints, 'sm'), map-get($grid-breakpoints, 'xxl'), $sm-font, $xxl-font);
    text-transform: uppercase;
    font-weight: 800;
	letter-spacing: 3px;
	line-height: 1;
	text-decoration: none;
	@if ($color != inherit) {
	color: $color;
	}
}
@mixin font-headline-small($color: color(white)) {
	@include font-headline($color, 18px, 17px);
	font-weight: 100;
}
@mixin font-section-header($color: color(pink)) {
	@include font-headline($color, 30px, 40px);
	text-align: center;
	width: 100%;

	&::after {
		content: '';
		display: block;
		width: 20%;
		height: 4px;
		background-color: $color;
		margin: 30px auto;
	}
}

@mixin font-mobile-nav($color: color(pink)) {
	@include font-headline($color, 20px, 30px);
}


body {
	@include font-body();
}

p,
ul,
ol {
	@include font-body();
	margin: 0 0 15px;
	
	&:empty {
		display: none;
	}

	&:last-child {
		margin-bottom: 0;
	}
}


h1, h2, h3, h4, h5, h6 {
	line-height: 1.5;
	margin: 0;
	@include color(black);
}

hr {
	border: transparent;
	height: 1px;
	@include color(gray, background);
	margin-bottom: 20px;
}

a {
	transition: all ease-in-out 300ms;

	&::before,
	&::after {
		display: inline-block;
	}
	@include hover() {
		transition: all ease-in-out 300ms;
	}
}

sup {
	font-size: 60%;
}