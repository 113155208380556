/**********/
/* Buttons
/**********/

$transition: all cubic-bezier(0.68, -0.55, 0.265, 1.55) 300ms;

//Alternative mixin to Bootstrap button-varient mixin
@mixin new-button($color, $background, $border) {
	display: inline-block;
	@include font-btn($color);
	background-color: $background;
	background-repeat: no-repeat;
	background-position-x: -100px;
	border-width: 0px;
	border-style: solid;
	border-color: $border;
	border-radius: 0px;
	padding: 6px 15px;
	text-align: center;
	opacity: 1;
	transition: $transition;
	
	@include screen(lg) {
	    padding: 6px 25px;
	}
	
	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		color: $color;
		border-color: darken($border, 10%);
		text-decoration: none;
		transform: scale(1.1);
		background-color: darken($background, 10%);
		box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
		background-position: 0px;
		transition: $transition;

		&.nohover {
			transform: inherit;
		}
	}
	
	&:active,
	&.active,
	.open > &.dropdown-toggle {
		background-image: none;
		box-shadow: none;
		transition: $transition;
	}
	
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: lighten($background, 20);
			border-color: lighten($border, 20);
			
		}
 	}
	
	.badge {
		color: $background;
		background-color: $color;
	}
  
}



.btn {
	@include new-button( color(white), color(pink), color(pink));
}

.btn__white {
	@include new-button( color(black), color(white), color(white));
}
.btn__blue {
	@include new-button( color(white), color(blue), color(blue));
}


.btn__block {
	@extend .btn;
	display: block;
}

.btn__transparent {
	@include new-button( color(white), color(transparent), color(white));
}

.donate-btn {
	@include new-button( color(white), color(pink), color(pink));
}